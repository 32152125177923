import { Column, Avatar } from '@gaz/gaz-components.public';

import { Container, Text, Row, HR } from 'common';
import { displayTime } from 'utils/time';
import { formatTitleAndName } from 'utils/string';

export default ({ handleClick, message }) => (
  <Container
    modifiers={['card', 'padding_2', 'withGutters']}
    onClick={handleClick}
  >
    <Row modifiers={['middle', 'spaceBetween']}>
      <Avatar
        modifiers={['round']}
        user={message.patient}
        width={50}
        height={50}
      />
      <Column modifiers={['col']}>
        <Row>
          <Text modifiers={['block']}>
            {formatTitleAndName(message.patient) || message.patientInfo.name}
          </Text>
        </Row>
        <Row>
          <Text modifiers={['block']}>
            {displayTime(message.createDate, 'hh:mm a, MM/DD/yy')}
          </Text>
        </Row>
      </Column>
    </Row>
    <HR modifiers={['gray']} />
    <Row modifiers={['topGutters_1', 'spaceBetween']}>
      <Text>
        From: <Text modifiers={['primary']}>{message.from}</Text>
      </Text>
      {/* <Text>{message.messageId}</Text> */}
    </Row>
    <Row modifiers={['topGutters_1', 'spaceBetween']}>
      <Text>
        Subject: <Text modifiers={['primary']}>{message.subject}</Text>
      </Text>
      {/* <Text>{message.messageId}</Text> */}
    </Row>
  </Container>
);
